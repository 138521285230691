import { useEffect, useState } from 'react';
import './../../../App.css';
import serviceData from '../../../data/serviceData';

export default function Gallery(){

    return(
        <>
            <div className='detail-page'>
                <div className='detail-page__banner-con'>
                    <div>GALLERY</div>
                    <img src='/images/menu_banner.jpg' decoding="async"/>
                </div>

                <div className='detail-page__con'>
                    <div className='detail-page__txt-line'></div>
                    <div className='detail-page__txt-con'>
                        <div className='detail-page__txt-title'>갤러리</div>
                    </div>

                    <div className='portfolio__con fade-in-self' style={{padding:'0'}}>
                        <ul className='portfolio__grid-con'>
                            {
                                ['01','02','03','04','05','06','07','08','09','10','11','12'].map((a, i) => {
                                    return <GridItem a={a} i={i} />
                                })
                            }
                        </ul>
                    </div>                    
                    
                </div>
            </div>
        </>
    )
}

function GridItem(props){    

    return (
        <>
            <li className='portfolio__grid-item'>
                <div className='portfolio__img-box' style={{backgroundImage:'url(/images/gallery/image' + props.a + '.webp)'}} ></div>
            </li>    
        </>
    )
}